<template>
  <v-container>
    <v-card>
      <v-toolbar dense flat class="teal" dark>
        <v-toolbar-title>Vincular Área/Bairro</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card-text>
        <form>
          <v-row align="center">
            <v-col>
              <v-select
                v-model="address.city"
                :items="citys"
                item-text="name"
                item-value="id"
                label="Cidade"
                return-value
                single-line
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="address.district"
                :counter="100"
                label="Nome do bairro"
                placeholder=" digite o nome do bairro"
                required
                outline
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="address.area"
                :items="areas"
                item-text="name"
                item-value="id"
                label="Área"
                return-value
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-btn class="mr-4" @click="submit">
            Cadastrar
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    name: { required }
  },
  data: () => ({
    address: {
      city: null,
      district: "",
      area: null
    },
    citys: [],
    areas: []
  }),
  created() {
    this.loadCitys();
    this.loadAreas();
  },
  computed: {},
  methods: {
    async submit() {
      try {
        const response = await this.$http.post("/address", this.address);

        if (response) return this.$toast.success("Cadastrado com sucesso!");
      } catch (error) {
        if (error.response.status === 400) {
          return this.$toast.error(
            "Falha ao cadastrar área, tente novamente!!!"
          );
        }
        return this.$toast.error("Tente novamente mais tarde!");
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
    },
    async loadCitys() {
      const response = await this.$http.get("/city");

      this.citys = response.data;
    },
    async loadAreas() {
      const response = await this.$http.get("/area");
      this.areas = response.data;
    }
  }
};
</script>

<style></style>
